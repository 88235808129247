import "./Modal.css";

function Modal({handleClose, show, children}) {
    const showHideClassName = show ? "modal display-block" : "modal display-none";

    return (
        <div className={showHideClassName}>
            <div className={"modal-main z-depth-1"}>
                {children}
                <button className={"btn cyan lighten-2"} onClick={() => handleClose(false)}>Cerrar</button>
            </div>
        </div>
    );
}

export default Modal;