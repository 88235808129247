import React, {useEffect, useState} from "react";

import currency from "currency.js";

import DatePicker from "../../components/DatePicker/DatePicker";
import Filter from "../../components/Filter/Filter";
import Table from "../../components/Table/Table";
import Pagination from "../../components/Pagination/Pagination";

import Insights from "../../api/Insights";

function DepositsList() {
    const [option, setOption] = useState({});

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [params, setParams] = useState({currentPage: 0, offset: 10});

    const [transactions, setTransactions] = useState([]);
    const [pages, setPages] = useState(0);

    const [total, setTotal] = useState("$0.00");

    // Set option to first element in Options array when component is loaded for the first time.
    useEffect(() => {
        setOption(options[0]);
    }, []);

    // Clear state when selected option changes
    useEffect(() => {
        setStartDate("");
        setEndDate("");
    }, [option]);

    // Request transactions when params change
    useEffect(() => {
        handleSearch();
        calculateTotal();
    }, [params]);

    const reset = () => {
        setParams({
            currentPage: 0,
            offset: 10
        });
        setOption(options[0])
    }

    const handleParams = () => {
        switch (option.id) {
            case 1:
                const startDateArr = startDate.split("-").map(value => parseInt(value));
                const date1 = new Date(startDateArr[0], startDateArr[1] - 1, startDateArr[2]);
                const date2 = new Date(new Date(startDateArr[0], startDateArr[1] - 1, startDateArr[2]).setDate(date1.getDate() + 1));

                setParams({
                    startDate: date1.getFullYear() + "-" + (date1.getMonth() + 1) + "-" + date1.getDate() + "T00:00:00",
                    endDate: date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate() + "T00:00:00",
                    currentPage: 0,
                    offset: 10,
                });
                break;
            case 2:
                setParams({
                    startDate: startDate,
                    endDate: endDate,
                    currentPage: 0,
                    offset: 10
                });
                break;
        }
    }

    const handleSearch = async () => {
        setPages(await Insights.transactionsPagination(params));
        setTransactions(await Insights.transactions(params));
    }

    const calculateTotal = async () => {
        const transactions = await Insights.transactions({
            startDate: params.startDate,
            endDate: params.endDate
        });

        let total = currency(0);
        transactions.forEach(transaction => {
            total = total.add(transaction.commissionAmount);
        });
        setTotal(total.format());
    }

    const options = [
        {
            id: 1,
            text: "Fecha",
            component: <DatePicker key={"1"} value={startDate} setValue={setStartDate} placeholder={"Elegir Fecha"}
                                   searchButton={true} handleSearch={handleParams}/>
        },
        {
            id: 2,
            text: "Rango de Fecha",
            component: (
                <React.Fragment key={"2"}>
                    <DatePicker value={startDate} setValue={setStartDate} placeholder={"Elegir Fecha Inicial"}/>
                    <DatePicker value={endDate} setValue={setEndDate} placeholder={"Elegir Fecha Final"}
                                searchButton={true} handleSearch={handleParams}/>
                </React.Fragment>
            )
        }
    ];

    // Set table details: Column header value and cell content
    const tableConfig = [
        {
            header: "ID Transacción",
            content: item => {
                return item.id;
            }
        },
        {
            header: "Hora",
            content: item => {
                return item.createdAt;
            }
        },
        {
            header: "Monto",
            content: item => {
                const amount = currency(item.fiatAmount);
                return `${amount.format()} ${item.fiatCurrency.iso}`;
            }
        },
        {
            header: "ID Emisor",
            content: item => {
                return item.userSellerId;
            }
        },
        {
            header: "ID Receptor",
            content: item => {
                return item.buyerUserId;
            }
        },
        {
            header: "Comisión",
            content: item => {
                const amount = currency(item.commissionAmount);
                return `${amount.format()} ${item.fiatCurrency.iso}`;
            }
        }
    ];

    return (
        <div className={"deposits-list"}>
            <Filter text={"Periodo"} options={options} option={option} setOption={setOption} resetFilter={reset}>
                {options.map(option => option.component)}
            </Filter>
            <div className={"deposits-amount"}>
                <label>Comisión total</label>
                <span>{total} MXN</span>
            </div>
            <Table config={tableConfig} data={transactions}/>
            <Pagination page={params.currentPage} setPage={(value) => setParams({...params, currentPage: value})} total={pages}/>
        </div>
    );
}

export default DepositsList;