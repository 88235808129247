import "./Filter.css";

import Dropdown from "../Dropdown/Dropdown";

function Filter({text, options, option, setOption, resetFilter, children}) {
    const renderOption = () => {
        const index = options.indexOf(options.find(value => value.id === option.id));
        return children[index];
    }

    return (
        <div className={"filter"}>
            <Dropdown text={text} options={options} value={option} handleValue={setOption}/>
            {renderOption()}
            {resetFilter !== undefined && <button className={"reset"} onClick={resetFilter}>Reset</button>}
        </div>
    );
}

export default Filter;