import {useState} from "react";
import {useNavigate} from "react-router-dom";

import CrypPayLogo from "../../images/Icon_CrypPayLarge.png";
import Account from "../../api/Account";
import M from "materialize-css";

function RecoverPassword() {
    // Hooks
    const navigate = useNavigate();

    // State
    const [email, setEmail] = useState("");

    const onEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const onSubmit = async (event) => {
        event.preventDefault();

        M.toast({
            html: 'Se ha enviado un correo a ' + email,
            classes: 'green'
        });

        navigate("/");

        try {
            await Account.beginRecoverPassword(email);
        } catch (e) {
            M.toast({
                html: 'Algo fallo, intenta nuevamente',
                classes: 'red'
            });
        }
    }

    return (
        <div className={"container grey lighten-4"}>
            <img src={CrypPayLogo} alt={""}/>
            <form className={"form"} onSubmit={onSubmit}>
                <div className={"input-field"}>
                    <input id={"login-email"} type={"email"} className={"validate"} value={email} onChange={onEmailChange}/>
                    <label htmlFor={"login-email"}>Email</label>
                </div>
                <button className={"btn"}>Recuperar contraseña</button>
            </form>
        </div>
    );
}

export default RecoverPassword;