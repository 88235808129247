import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import Dropdown from "../../../components/Dropdown/Dropdown";
import Filter from "../../../components/Filter/Filter";
import SearchField from "../../../components/SearchField/SearchField";
import Insights from "../../../api/Insights";
import Dates from "../../../utils/Dates";
import moment from "moment";

function TransactorsChart() {

  const handleToggleClick = () => {
    setSelectedPriority(selectedPriority === 1 ? 2 : 1);
  };

  const handleDepositsGraphClick = async () => {
    let date = "";
    let period = "";
    let priority = selectedPriority;

    if (option.id === 4) {
      date = year.id + "-01-01";
      period = option.id;
    } else if (option.id === 3) {
      date = month + "-01";
      period = option.id;
    } else if (option.id === 2) {
      date = moment(week).toDate().toISOString();
      period = option.id;
    }

    try {
      setShowChart(true);
      const response = await Insights.transactorsGraph(date, period, priority);
      console.log(response)
      const chartData = response.map((item) => ({
        x: item.store.name,
        y: selectedPriority === 1 ? item.incidences : item.balance,
      }));
      const chartLabels = option.id === 3 ? generateMonthDayLabels(date) : response.map((item) => getMonthName(item.incidenceDate));

      let titleText = "";
      if (option.id === 4) {
        titleText = `Depósitos en ${moment(date).format("YYYY")}`;
      } else if (option.id === 3) {
        titleText = `Depósitos en ${moment(date).format("MMMM YYYY")}`;
      } else if (option.id === 2) {
        titleText = `Depósitos en ${moment(date).format("DD/MM/YYYY")}`;
      }

      setSeries([
        {
          name: "Balance",
          data: chartData,
        },
      ]);

      setChartOptions({
        chart: {
          type: "bar",
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '10%', 
          },
        },
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          title: {
            text: selectedPriority === 1 ? "Incidences" : "Balance",
          },
        },
        yaxis: {
          categories: chartData.map(item => item.x),
        },
        title: {
          text: titleText,
          align: "left",
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getMonthName = (date) => {
    const parsedDate = moment(date);
    parsedDate.locale("es");
    return parsedDate.format("MMM");
  };

  const generateMonthDayLabels = (date) => {
    const daysInMonth = moment(date).daysInMonth();
    const dayLabels = Array.from({ length: daysInMonth }, (_, i) => (i + 1).toString());
    return dayLabels;
  };

  const [year, setYear] = useState({});
  const [month, setMonth] = useState("");
  const [week, setWeek] = useState("");
  const [option, setOption] = useState({});
  const [showChart, setShowChart] = useState(false);
  const [selectedPriority, setSelectedPriority] = useState(1);


  useEffect(() => {
    setYear("");
    setMonth("");
    setWeek("");
  }, [option]);

  const [series, setSeries] = useState([
    {
      name: "Balance",
      data: [],
    },
  ]);

  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "bar",
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      title: {
        text: "Depósitos",
      },
    },
    title: {
      text: "",
      align: "left",
    },
  });

  const options = [
    {
      id: 4,
      text: "Anual",
      component: (
        <Dropdown
          key={"1"}
          text={"Año"}
          options={Dates.getYears()}
          value={year}
          handleValue={setYear}
          searchButton={true}
          handleSearch={handleDepositsGraphClick}
        />
      ),
    },
    {
      id: 3,
      text: "Mensual",
      component: (
        <SearchField
          key={"2"}
          type={"month"}
          value={month}
          setValue={setMonth}
          searchButton={true}
          handleSearch={handleDepositsGraphClick}
        />
      ),
    },
    {
      id: 2,
      text: "Semanal",
      component: (
        <SearchField
          key={"3"}
          type={"week"}
          value={week}
          setValue={setWeek}
          searchButton={true}
          handleSearch={handleDepositsGraphClick}
        />
      ),
    },
  ];

  return (
    <>
      <div className={"transaction-charts"}>
 
        <Filter text={"Periodo"} options={options} option={option} setOption={setOption}>
          {options.map((option) => option.component)}
        </Filter>
        <div className="priority-button">
        <span className="span-order">Ordenar por:</span>
        <button
            onClick={handleToggleClick}
            style={{
                background: selectedPriority === 1 ? "#3498db" : "#3498db",
                color: "#fff",
                borderRadius: "50px",
                border: "none",
                padding: "10px 20px",
                cursor: "pointer",
                outline: "none",
            }}
>
  {selectedPriority === 1 ? "Más Ventas" : "Más Ingresos"}
</button>

        </div>
        <div className={"chart"}>
          
          {showChart ? (
            <Chart type={"bar"} height={600} width={1000} options={chartOptions} series={series} />
          ) : (
            <p>Elige un periodo</p>
          )}
        </div>
      </div>
    </>
  );
}

export default TransactorsChart;
