import React from 'react';
import DepositsChart from './DeposistsChart';
import Insights from '../../../api/Insights';

function DepositsGraphics() {
    const handleDepositsGraphClick = async () => {
        const date = '2023-10-10';
        const period = '2';
        const role = '3';

        try {
            const response = await Insights.depositsGraph(date, period, role);
            console.log(response); 
        } catch (error) {
            console.error(error); 
        }
    }

    return (
        <>
            <div className="grid-charts">
                <DepositsChart />
                <DepositsChart />
                <DepositsChart />
                <DepositsChart />
            </div>
            <button onClick={handleDepositsGraphClick}>Buscar</button>
        </>
    )
}

export default DepositsGraphics;
