import "./Table.css";

function Table({config, data}) {
    const renderedHeaders = config.map((column, key) => {
        return (
            <th key={"header-" + key} className={"th"}>{column.header}</th>
        );
    });

    const renderedData = data.map(item => {
        return (
            <tr key={item.id}>
                {config.map(column => {
                    return (
                        <td key={column.header + "-" + item.id}>
                            {column.content(item)}
                        </td>
                    );
                })}
            </tr>
        );
    });

    if (data.length > 0) {
        return (
            <table className={"centered"}>
                <thead>
                <tr>{renderedHeaders}</tr>
                </thead>
                <tbody>
                {renderedData}
                </tbody>
            </table>
        );
    } else {
        return (
            <div className={"empty-data"}>No hay nada que mostrar</div>
        )
    }
}

export default Table;