import React from 'react';
import PersonalChart from './PersonalChart';
import Insights from '../../../api/Insights';

function PersonalGraphics() {
    const handleAccountsGraphClick = async () => {
        const date = '2023-10-10';
        const period = '2';
        const role = '3';

        try {
            const response = await Insights.personalGraph(date, period, role);
            console.log(response); 
        } catch (error) {
            console.error(error); 
        }
    }

    return (
        <>
            <div className="grid-charts">
                <PersonalChart />
                <PersonalChart />
                <PersonalChart />
                <PersonalChart />
            </div>
            <button onClick={handleAccountsGraphClick}>Buscar</button>
        </>
    )
}

export default PersonalGraphics;
