import React, {useEffect, useState} from "react";

import Filter from "../../components/Filter/Filter";
import Dropdown from "../../components/Dropdown/Dropdown";
import SearchField from "../../components/SearchField/SearchField";

import Insights from "../../api/Insights";
import Dates from "../../utils/Dates";

function TransactionReport() {
    // State
    const [year, setYear] = useState({});
    const [month, setMonth] = useState("");
    const [reportUrl, setReportUrl] = useState(null);
    const [option, setOption] = useState({});

    useEffect(() => {
        setOption(options[0]);
    }, []);

    // Clear state when option changes
    useEffect(() => {
        setYear({});
        setMonth("")
    }, [option]);

    const reset = () => {
        setOption(options[0]);
        setReportUrl(null);
    }

    const handleSearch = async () => {
        let date;

        if (option.id === 4) { // Year
            date = year.id + "-01-01";
        } else { // Month
            date = month + "-01";
        }

        try {
            const response = await Insights.exportData(date, option.id, 1);
            setReportUrl(response.url);
        } catch (err) {
            if (err.response.status === 404) {
                setReportUrl("NO DATA");
            }
        }
    }

    const options = [
        {
            id: 4,
            text: "Anual",
            component: <Dropdown key={"1"} text={"Año"} options={Dates.getYears()} value={year} handleValue={setYear}
                                 searchButton={true} handleSearch={handleSearch}/>
        },
        {
            id: 3,
            text: "Mensual",
            component: <SearchField key={"2"} type={"month"} value={month} setValue={setMonth} searchButton={true}
                                    handleSearch={handleSearch}/>
        }
    ];

    const renderReport = () => {
        if (reportUrl && reportUrl !== "NO DATA") {
            return (
                <div className={"report"}>
                    <a className={"btn cyan lighten-2"} href={reportUrl}>
                        Descargar reporte
                        <i className={"material-icons right"}>file_download</i>
                    </a>
                </div>
            )
        } else if (reportUrl && reportUrl === "NO DATA") {
            return <div className={"report"}>No se encontraron transacciones en el periodo solicitado</div>;
        }

        return <div className={"report"}>Elige un periodo</div>;
    }

    return (
        <div className={"transaction-report"}>
            <Filter text={"Periodo"} options={options} option={option} setOption={setOption} resetFilter={reset}>
                {options.map(option => option.component)}
            </Filter>
            {renderReport()}
        </div>
    );
}

export default TransactionReport;