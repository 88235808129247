import "./ControlPanel.css";

import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import currency from "currency.js";

import Insights from "../../api/Insights";
import {changePage} from "../Dashboard/DashboardSlice";

function ControlPanel() {
    // Hooks
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // State
    const [transactionAmount, setTransactionAmount] = useState("$0.00");
    const [depositsAmount, setDepositsAmount] = useState("$0.00");

    const getAmount = async () => {
        try {
            const startDate = "2000-01-01T00:00:00";
            const date = new Date(new Date().setDate(new Date().getDate() + 1));
            //const endDate = Dates.getDateInISOString(date.getFullYear(), date.getMonth(), date.getDate());
            const endDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + "T00:00:00";

            const transactions = await Insights.transactions({startDate, endDate});

            // Calculate transaction and deposits total
            let transactionTotal = currency(0);
            let depositsTotal = currency(0);
            transactions.forEach(transaction => {
                transactionTotal = transactionTotal.add(transaction.fiatAmount);
                depositsTotal = depositsTotal.add(transaction.commissionAmount);
            });

            setTransactionAmount(transactionTotal.format());
            setDepositsAmount(depositsTotal.format());
        } catch (e) {
        }
    }

    // Request transaction and deposits data when component is loaded
    useEffect(() => {
        // Added timeout to allow Authorization header to be built, otherwise the first time the page is loaded a 401 is returned.
        const timer = setTimeout(() => {
            getAmount();
        }, 100);

        return () => clearTimeout(timer);
    }, []);

    const handleNavigation = (value) => {
        dispatch(changePage(value));
        navigate(`/${value}`);
    }

    return (
        <div className={"control-panel section"}>
            <h5>Tu información al día de hoy</h5>
            <div className={"panel"}>
                <div className={"panel-element z-depth-1"}>
                    <div className={"panel-element-title"} onClick={() => handleNavigation("transactions")}>
                        <i className={"material-icons transaction-icon"}>currency_exchange</i>
                        <span>Transacciones</span>
                        <i className={"material-icons"}>navigate_next</i>
                    </div>
                    <div className={"panel-element-content"}>
                        <span className={"amount"}>{transactionAmount}</span>
                        <span className={"currency"}>Monto (MXN)</span>
                    </div>
                </div>
                <div className={"panel-element z-depth-1"}>
                    <div className={"panel-element-title"} onClick={() => handleNavigation("deposits")}>
                        <i className={"material-icons deposit-icon"}>account_balance</i>
                        <span>Depósitos</span>
                        <i className={"material-icons"}>navigate_next</i>
                    </div>
                    <div className={"panel-element-content"}>
                        <span className={"amount"}>{depositsAmount}</span>
                        <span className={"currency"}>Monto (MXN)</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ControlPanel;