import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

import Dropdown from "../../../components/Dropdown/Dropdown";
import Filter from "../../../components/Filter/Filter";
import SearchField from "../../../components/SearchField/SearchField";

import Insights from "../../../api/Insights";
import Dates from "../../../utils/Dates";
import moment from "moment";

function BusinessChart() {
    const handleAccountsGraphClick = async () => {
        let date = "";
        let period = "";
        let role = "3";
    
        if (option.id === 4) {
            date = year.id + "-01-01";
            period = option.id;
        } else if (option.id === 3) {
            date = month + "-01";
            period = option.id;
        } else if (option.id === 2) {
            date = moment(week).toDate().toISOString();
            period = option.id;
        }
    
        try {
            const response = await Insights.accountsGraph(date, period, role);   
            console.log(response) 
            const chartData = response.map((item) => item.incidences);
            const chartLabels = option.id === 3 ? generateMonthDayLabels(date) : response.map((item) => getMonthName(item.incidenceDate));
    
            let titleText = "";
            if (option.id === 4) {
                titleText = `Clientes en ${moment(date).format("YYYY")}`;
            } else if (option.id === 3) {
                titleText = `Clientes en ${moment(date).format("MMMM YYYY")}`;
            } else if (option.id === 2) {
                titleText = `Clientes en ${moment(date).format("DD/MM/YYYY")}`;
            }
    
            setSeries([
                {
                    name: "Clientes",
                    data: chartData,
                },
            ]);
    
            setChartOptions({
                ...chartOptions,
                xaxis: {
                    categories: chartLabels,
                },
                title: {
                    text: titleText,
                },
            });
        } catch (error) {
            console.error(error);
        }
    };
    
    const getMonthName = (date) => {
        const parsedDate = moment(date);
        parsedDate.locale("es"); 
        return parsedDate.format("MMM");
    };
    
    
    const generateMonthDayLabels = (date) => {
        const daysInMonth = moment(date).daysInMonth();
        const dayLabels = Array.from({ length: daysInMonth }, (_, i) => (i + 1).toString());
        return dayLabels;
    };
    
    // State
    const [year, setYear] = useState({});
    const [month, setMonth] = useState("");
    const [week, setWeek] = useState("");
    const [option, setOption] = useState({});
    
    // Clear state when option changes
    useEffect(() => {
        setYear("");
        setMonth("");
        setWeek("");
    }, [option]);
    
    // State - Chart
    const [series, setSeries] = useState([
        {
            name: "Transacciones",
            data: [],
        },
    ]);
    
    const [chartOptions, setChartOptions] = useState({
        chart: {
            zoom: { enabled: false },
        },
        grid: {
            row: {
                colors: ["#f3f3f3", "transparent"],
                opacity: 0.5,
            },
        },
        title: {
            text: "",
            align: "left",
        },
        xaxis: {
            categories: [],
        },
    });
    
    const options = [
        {
            id: 4,
            text: "Anual",
            component: (
                <Dropdown
                    key={"1"}
                    text={"Año"}
                    options={Dates.getYears()}
                    value={year}
                    handleValue={setYear}
                    searchButton={true}
                    handleSearch={handleAccountsGraphClick}
                />
            ),
        },
        {
            id: 3,
            text: "Mensual",
            component: (
                <SearchField
                    key={"2"}
                    type={"month"}
                    value={month}
                    setValue={setMonth}
                    searchButton={true}
                    handleSearch={handleAccountsGraphClick}
                />
            ),
        },
        {
            id: 2,
            text: "Semanal",
            component: (
                <SearchField
                    key={"3"}
                    type={"week"}
                    value={week}
                    setValue={setWeek}
                    searchButton={true}
                    handleSearch={handleAccountsGraphClick}
                />
            ),
        },
    ];
    
    const renderChart = () => {
        if (chartOptions.xaxis.categories.length === 0) {
            return "Elige un periodo";
        } else {
            return <Chart type={"line"} width={"500"} options={chartOptions} series={series} />;
        }
    };
    
    return (
        <>
            <div className={"transaction-charts"}>
                <Filter text={"Periodo"} options={options} option={option} setOption={setOption}>
                    {options.map((option) => option.component)}
                </Filter>
                <div className={"chart"}>{renderChart()}</div>
            </div>
        </>
    );
}
    
export default BusinessChart;
