import CrypPay from "./CrypPay";

class Account {
    activate = async (id) => {
        const response = await CrypPay.put("/Account/Restore/" + id);
        return response.data.response;
    }

    suspend = async (id) => {
        const response = await CrypPay.put("/Account/Suspend/" + id);
        return response.data.response;
    }

    delete = async (id) => {
        const response = await CrypPay.delete("/Account", {
            params: {
                id: id
            }
        });

        return response.data.response;
    }

    get = async ({name, email, id, currentPage, offset}) => {
        const response = await CrypPay.get("/Account", {
            params: {
                key: name,
                id: id,
                currentPage: currentPage,
                offset: offset,
                role: 3
            }
        });

        return response.data.response;
    }

    updateClientStatus = async (id) => {
        const response = await CrypPay.put("/Account/Approve", {
            id: id,
           // accountStatusId: clientStatus
        });

        return response.data.response;
    }

    getClient = async (id) => {
        const response = await CrypPay.get("/Account", {
            params: {
                id: id
            }
        });
        
        return response.data.response[0];
    }

    getListPagination = async ({email, name, id}) => {
        const response = await CrypPay.get("/Account/Pagination", {
            params: {
                key: email,
                id: id,
                role: 3,
                splitBy: 10,
            }
        });

        return response.data.response;
    }

    beginRecoverPassword = async (email) => {
        const response = await CrypPay.post("/Account/BeginRecoverPassword", {
            email: email
        });

        return response.data.response;
    }

    completeRecoverPassword = async (password, token) => {
        const response = await CrypPay.put("/Account/CompleteRecoverPassword", {
            token: token,
            password: password
        });

        return response.data.response;
    }
}

export default new Account();