import "./Dashboard.css";

import {Navigate, Outlet} from "react-router-dom";
import {useAuth} from "../../providers/authProvider";

import Header from "../../components/Header/Header";
import Menu from "../../components/Menu/Menu";

const Dashboard = () => {
    const {auth} = useAuth();

    // Check if the user is authenticated
    if (!auth) {
        // If not authenticated, redirect to login page
        return <Navigate to={"/login"}/>;
    }

    // If authenticated, render the children routes
    return (
        <div className={"dashboard grey lighten-4"}>
            <Header/>
            <Menu/>
            <Outlet/>
        </div>
    );
}

export default Dashboard;