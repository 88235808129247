import CrypPay from "./CrypPay";

class Recommendations {
    getRecommendations = async () => {
        const response = await CrypPay.get("/CustomerBusinessRecommendation", {
        });

        return response.data.response;
    }
}

export default new Recommendations();