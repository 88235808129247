import CrypPay from "./CrypPay";

class Store {
    getList = async ({name, id, companyId, currentPage, offset}) => {
        const response = await CrypPay.get("/Store", {
            params: {
                name: name,
                id: id,
                companyId: companyId,
                currentPage: currentPage,
                offset: offset
            }
        });

        return response.data.response;
    }

    getListPagination = async ({name, id, companyId}) => {
        const response = await CrypPay.get("/Store/Pagination", {
            params: {
                name: name,
                id: id,
                companyId: companyId,
                splitBy: 10
            }
        });

        return response.data.response;
    }
}

export default new Store();