import React, {useEffect, useState} from "react";
import Chart from "react-apexcharts";

import Dropdown from "../../components/Dropdown/Dropdown";
import Filter from "../../components/Filter/Filter";
import SearchField from "../../components/SearchField/SearchField";

import Dates from "../../utils/Dates";
import Insights from "../../api/Insights";
import moment from "moment";

function TransactionCharts() {
    // State
    const [year, setYear] = useState({});
    const [month, setMonth] = useState("");
    const [week, setWeek] = useState("");
    const [option, setOption] = useState({});

    // Clear state when option changes
    useEffect(() => {
        setYear("");
        setMonth("");
        setWeek("");
    }, [option]);

    // State - Chart
    const [series, setSeries] = useState([{
        name: "Transacciones",
        data: []
    }]);

    const [chartOptions, setChartOptions] = useState({
        chart: {
            zoom: {enabled: false}
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        title: {
            text: "",
            align: "left"
        },
        xaxis: {
            categories: [],
        }
    });

    // Update chart options
    const updateChartOptions = (categories, title) => {
        setChartOptions({
            ...chartOptions,
            xaxis: {
                categories: categories
            },
            title: {
                text: title
            }
        });
    }

    const updateChartSeries = (data) => {
        let tempSeries = [...series];
        let serie = {...tempSeries[0]}
        serie.data = data.map(item => item.incidences);
        tempSeries[0] = serie;
        setSeries(tempSeries);
    }

    const handleSearch = async () => {
        let date = "";
        let date1;
        let response;

        switch (option.id) {
            case 4: // Year
                date = year.id + "-01-01";
                response = await Insights.charts(date, option.id);

                // Update options
                updateChartOptions(
                    ['Ene', 'Feb', 'Mar', 'Abr', 'Mayo', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dec'],
                    "Transacciones en " + year.id);

                // Update series
                updateChartSeries(response);

                break;
            case 3: // Month
                date = month + "-01";
                response = await Insights.charts(date, option.id);

                date1 = new Date(date);
                // Update options
                updateChartOptions(
                    response.map((item, index) => index + 1),
                    "Transacciones en " + Dates.getMonthName(date1.getUTCMonth()) + " " + date1.getFullYear()
                );

                // Update series
                updateChartSeries(response);

                break;
            case 2: // Week
                date = moment(week).toDate();
                date1 = moment(week).add(6, "days").toDate();
                response = await  Insights.charts(date.toISOString(), option.id);

                console.log(response);

                // Update options
                updateChartOptions(
                    ["Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado", "Domingo"],
                    "Transacciones del " + date.getDate() + " de " + Dates.getMonthName(date.getUTCMonth()) + " al " + date1.getDate() + " de " + Dates.getMonthName(date1.getUTCMonth())
                )

                // Update series
                updateChartSeries(response);

                break;
        }
    }

    const options = [
        {
            id: 4,
            text: "Anual",
            component: <Dropdown key={"1"} text={"Año"} options={Dates.getYears()} value={year} handleValue={setYear}
                                 searchButton={true} handleSearch={handleSearch}/>
        },
        {
            id: 3,
            text: "Mensual",
            component: <SearchField key={"2"} type={"month"} value={month} setValue={setMonth} searchButton={true}
                                    handleSearch={handleSearch}/>
        },
        {
            id: 2,
            text: "Semanal",
            component: <SearchField key={"3"} type={"week"} value={week} setValue={setWeek} searchButton={true}
                                    handleSearch={handleSearch}/>
        }
    ];

    const renderChart = () => {
        if (chartOptions.xaxis.categories.length === 0) {
            return "Elige un periodo";
        } else {
            return <Chart type={"line"} width={"500"} options={chartOptions} series={series}/>;
        }
    }

    return (
        <div className={"transaction-charts"}>
            <Filter text={"Periodo"} options={options} option={option} setOption={setOption}>
                {options.map(option => option.component)}
            </Filter>
            <div className={"chart"}>{renderChart()}</div>
        </div>
    );
}

export default TransactionCharts;