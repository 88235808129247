import React from 'react';
import TransactorsChart from './TransactorsChart';
import './Transactors.css'
import Insights from '../../../api/Insights';

function TransactorsGraphic() {
    const handleDepositsGraphClick = async () => {
        const date = '2023-10-10';
        const period = '2';
        const role = '3';

        try {
            const response = await Insights.transactorsGraph(date, period, role);
            console.log(response); 
        } catch (error) {
            console.error(error); 
        }
    }

    return (
        <>
            <div className="container-transactor">
                <TransactorsChart />
            
            </div>
            <button onClick={handleDepositsGraphClick}>Buscar</button>
        </>
    )
}

export default TransactorsGraphic;
