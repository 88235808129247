import "./Header.css";
import {useEffect, useRef, useState} from "react";
import {useAuth} from "../../providers/authProvider";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

function Header() {
    // Hooks
    const {auth, setAuth} = useAuth();
    const navigate = useNavigate();
    const ref = useRef();

    // State
    const currentPage = useSelector((state) => state.dashboard.currentPage);
    const [username, setUsername] = useState(auth.identity.firstname);
    const [expanded, setExpanded] = useState(false);

    // Add eventListener to know when a click is made outside account dropdown
    useEffect(() => {
        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        }
    }, []);

    const handleClick = (event) => {
        if (!ref.current.contains(event.target)) {
            setExpanded(false);
        }
    }

    const handleDropdownClick = () => {
        setExpanded(!expanded);
    }

    const handleLogout = () => {
        // Remove auth data
        setAuth();

        // Redirect to home
        navigate("/");
    }

    const getHeader = () => {
        switch (currentPage) {
            case "control-panel": return "Panel de Control";
            case "transactions": return "Transacciones";
            case "deposits": return "Depósitos";
            case "business": return "CrypPay Business";
            case "personal": return "CrypPay Personal";
            case "recomendations": return "Recomendaciones";
            case "insights": return "Insights";
            default: return "";
        }
    }

    return (
        <div className={"header"}>
            <h4>{getHeader()}</h4>
            <div className={"dropdown username"}  ref={ref} onClick={handleDropdownClick}>
                <div>
                    {username}
                    <i className={"material-icons right"}>arrow_drop_down</i>
                </div>
                <div className={"dropdown-menu z-depth-1 " + (expanded ? "expanded" : "")}>
                    <div className={"dropdown-item"} onClick={handleLogout}>
                        Cerrar sesión
                        <i className={"material-icons right"}>logout</i>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;