import React, {useEffect, useState} from "react";

import Insights from "../../api/Insights";

import DatePicker from "../../components/DatePicker/DatePicker";
import SearchField from "../../components/SearchField/SearchField";
import Filter from "../../components/Filter/Filter";
import Table from "../../components/Table/Table";
import Pagination from "../../components/Pagination/Pagination";
import Modal from "../../components/Modal/Modal";
import TransactionModal from "./TransactionModal";
import currency from "currency.js";

function TransactionList() {
    const [show, setShow] = useState(false);
    const [transaction, setTransaction] = useState({});
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [transactionId, setTransactionId] = useState("");
    const [payee, setPayee] = useState("");
    const [option, setOption] = useState({});
    const [pages, setPages] = useState(0);
    const [transactions, setTransactions] = useState([]);
    const [params, setParams] = useState({
        currentPage: 0,
        offset: 10
    });

    // Set option to first element in Options array when component is loaded for the first time.
    useEffect(() => {
        setOption(options[0]);
    }, []);

    // Clear state when selected option changes
    useEffect(() => {
        setStartDate("");
        setEndDate("");
        setTransactionId("");
        setPayee("");
    }, [option]);

    // Update params when page changes
    useEffect(() => {
        handleSearch();
    }, [params]);

    // Request transactions when params change
    useEffect(() => {
        const timer = setTimeout(() => {
            handleSearch();
        }, 200);

        return () => clearTimeout(timer);

    }, [params]);

    const reset = () => {
        setParams({
            currentPage: 0,
            offset: 10
        });
        setOption(options[0])
    }

    const handleParams = () => {
        switch (option.id) {
            case 1:
                const startDateArr = startDate.split("-").map(value => parseInt(value));
                const date1 = new Date(startDateArr[0], startDateArr[1] - 1, startDateArr[2]);
                const date2 = new Date(new Date(startDateArr[0], startDateArr[1] - 1, startDateArr[2]).setDate(date1.getDate() + 1));

                setParams({
                    startDate: date1.getFullYear() + "-" + (date1.getMonth() + 1) + "-" + date1.getDate() + "T00:00:00",
                    endDate: date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate() + "T00:00:00",
                    currentPage: 0,
                    offset: 10,
                });
                break;

            case 2:
                setParams({
                    startDate: startDate,
                    endDate: endDate,
                    currentPage: 0,
                    offset: 10
                });
                break;

            case 3:
                setParams({
                    orderId: transactionId,
                    currentPage: 0,
                    offset: 10
                });
                break;

            case 4:
                setParams({
                    buyerUserId: payee,
                    currentPage: 0,
                    offset: 10
                });
                break;

            default:
                return;
        }
    }

    const handleSearch = async () => {
        setPages(await Insights.transactionsPagination(params));
        setTransactions(await Insights.transactions(params));
    }

    // Set Filter options
    const options = [
        {
            id: 1,
            text: "Fecha",
            component: <DatePicker key={"1"} value={startDate} setValue={setStartDate} placeholder={"Elegir Fecha"}
                                   searchButton={true} handleSearch={handleParams}/>
        },
        {
            id: 2,
            text: "Rango de Fecha",
            component: <React.Fragment key={"2"}>
                <DatePicker value={startDate} setValue={setStartDate} placeholder={"Elegir Fecha Inicial"}/>
                <DatePicker value={endDate} setValue={setEndDate} placeholder={"Elegir Fecha Final"}
                            searchButton={true} handleSearch={handleParams}/>
            </React.Fragment>
        },
        {
            id: 3,
            text: "No. de Transacción",
            component: <SearchField key={"3"} value={transactionId} setValue={setTransactionId}
                                    placeholder={"No. de Transacción"}
                                    searchButton={true} handleSearch={handleParams}/>
        },
        {
            id: 4,
            text: "ID de Receptor",
            component: <SearchField key={"4"} value={payee} setValue={setPayee} placeholder={"ID de Receptor"}
                                    searchButton={true} handleSearch={handleParams}/>
        }
    ];

    // Set table details: Column header value and cell content
    const tableConfig = [
        {
            header: "ID Transacción",
            content: item => {
                return item.id;
            }
        },
        {
            header: "Hora",
            content: item => {
                return item.createdAt;
            }
        },
        {
            header: "Monto",
            content: item => {
                const amount = currency(item.fiatAmount);
                return `${amount.format()} ${item.fiatCurrency.iso}`;
            }
        },
        {
            header: "ID Emisor",
            content: item => {
                return item.userSellerId;
            }
        },
        {
            header: "ID Receptor",
            content: item => {
                return item.buyerUserId;
            }
        },
        {
            header: "Detalles",
            content: (item) => <i className={"material-icons"} onClick={() => {
                setTransaction(item);
                setShow(true);
            }}>receipt_long</i>
        }
    ];

    return (
        <div className={"transaction-list"}>
            <Filter text={"Buscar por"} options={options} option={option} setOption={setOption} resetFilter={reset}>
                {options.map(option => option.component)}
            </Filter>
            <Table config={tableConfig} data={transactions}/>
            <Pagination page={params.currentPage} setPage={(value) => setParams({...params, currentPage: value})}
                        total={pages}/>
            <Modal handleClose={setShow} show={show}>
                <TransactionModal transaction={transaction}/>
            </Modal>
        </div>
    );
}

export default TransactionList;