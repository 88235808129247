import "./Login.css";

import CrypPayLogo from "../../images/Icon_CrypPayLarge.png";

import {useState} from "react";
import {useAuth} from "../../providers/authProvider";
import {Link, useNavigate} from "react-router-dom";

import Auth from "../../api/Auth";

function Login() {
    // Hooks
    const navigate = useNavigate();
    const {setAuth} = useAuth();

    // State
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [visibility, setVisibility] = useState("visibility");
    const [passwordType, setPasswordType] = useState("password")

    const onEmailChange = e => {
        setError("");
        setEmail(e.target.value);
    }

    const onPasswordChange = e => {
        setError("");
        setPassword(e.target.value);
    }

    const onSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await Auth.login(email, password);

            // Update auth data
            setAuth(response);

            // Redirect to home
            navigate("/");
        } catch (e) {
            if (e.response.data.statusCode === 401) {
                setError("Credenciales invalidas");
            } else {
                setError("Algo salio mal, intentalo nuevamente")
            }
        }
    }

    const togglePassword = () => {
        if (visibility === "visibility") {
            setVisibility("visibility_off");
            setPasswordType("text");
        } else {
            setVisibility("visibility");
            setPasswordType("password");
        }
    }

    return (
        <div className={"container grey lighten-4"}>
            <img src={CrypPayLogo} alt={""}/>
            <form className={"form login"} onSubmit={onSubmit}>
                <div className={"input-field"}>
                    <input id={"login-email"} type={"email"} className={"validate"} value={email}
                           onChange={onEmailChange}/>
                    <label htmlFor={"login-email"}>Email</label>
                </div>
                <div className={"input-field"}>
                    <input id={"login-password"} type={passwordType} className={"validate"} value={password}
                           onChange={onPasswordChange}/>
                    <label htmlFor={"login-password"}>Password</label>
                    <i className={"material-icons show-password"} onClick={togglePassword}>{visibility}</i>
                </div>
                {error !== "" && <span className={"error"}>{error}</span>}
                <button className={"btn"}>Log in</button>
                <Link to={"/recover-password"}>Recuperar contraseña</Link>
            </form>
        </div>
    );
}

export default Login;