import {useState} from "react";
import Modal from "../../components/Modal/Modal";

function StoreModal({store}) {
    const [show, setShow] = useState(false);
    const [image, setImage] = useState("");

    const renderAddress = () => {
        if (store.address) {
            return (
                <div className={"modal-group three-columns"}>
                    <label className={"modal-group-title"}>Domicilio</label>
                    <div className={"modal-field"}>
                        <label>Calle</label>
                        <span>{store.address.name}</span>
                    </div>
                    <div className={"modal-field"}>
                        <label>C.P.</label>
                        <span>{store.address.postalcode}</span>
                    </div>
                    <div className={"modal-field"}>
                        <label>Estado</label>
                        <span>{store.address.state.name}</span>
                    </div>
                    <div className={"modal-field"}>
                        <label>Pais</label>
                        <span>{store.address.country.name}</span>
                    </div>
                    <div className={"modal-field"}>
                        <label>Descripcion</label>
                        <span>{store.address.description}</span>
                    </div>
                </div>
            );
        }
        return null;
    }

    const renderManager = () => {
        if (store.storeManager) {
            return (
                <div className={"modal-group three-columns"}>
                    <label className={"modal-group-title"}>Gerente</label>
                    <div className={"modal-field"}>
                        <label>Nombres</label>
                        <span>{store.storeManager.firstname} {store.storeManager.middlename}</span>
                    </div>
                    <div className={"modal-field"}>
                        <label>Apellido(s)</label>
                        <span>{store.storeManager.lastname}</span>
                    </div>
                    <div className={"modal-field"}>
                        <label>Telefono</label>
                        <span>{store.storeManager.phone}</span>
                    </div>
                    <div className={"modal-field"}>
                        <label>Correo electronico</label>
                        <span>{store.storeManager.email}</span>
                    </div>
                </div>
            );
        }
        return null;
    }

    const getAccountStatusId = (id) => {
        switch (id) {
            case 1:
                return "Activo";
            case 2:
                return "Suspendido";
            case 3:
                return "Eliminado";
            case 4:
                return "Pendiente";
        }
    }

    const renderUsers = () => {
        if (store.users) {
            const renderedUsers = store.users.map(user => {
                return (
                    <div key={"user-" + user.id} className={"modal-group"}>
                        <div className={"modal-field"}>
                            <label>Nombre</label>
                            <span>{user.firstname} {user.lastname}</span>
                        </div>
                        <div className={"modal-field"}>
                            <label>Telefono</label>
                            <span>{user.phone}</span>
                        </div>
                        <div className={"modal-field"}>
                            <label>Correo electronico</label>
                            <span>{user.email}</span>
                        </div>
                        <div className={"modal-field"}>
                            <label>Status</label>
                            <span>{getAccountStatusId(user.accountStatusId)}</span>
                        </div>
                    </div>
                );
            });

            return (
                <div className={"modal-group"}>
                    <label className={"modal-group-title"}>Usuarios</label>
                    <div className={"users"}>
                        {renderedUsers}
                    </div>
                </div>
            );
        }
        return null;
    }

    const renderedPictures = () => {
        if (store.officialDoccumentation) {
            return (
                <>
                    {
                        store.officialDoccumentation.officialIdMedia.url &&
                        <div className={"document"}>
                            <span>Identificación Oficial</span>
                            <i className={"material-icons"} onClick={() => {
                                setImage(store.officialDoccumentation.officialIdMedia.url);
                                setShow(true);
                            }}>visibility</i>
                        </div>
                    }
                    {
                        store.officialDoccumentation.addressProofMedia.url &&
                        <div className={"document"}>
                            <span>Comprobante de domicilio</span>
                            <i className={"material-icons"} onClick={() => {
                                setImage(store.officialDoccumentation.addressProofMedia.url);
                                setShow(true)
                            }}>visibility</i>
                        </div>
                    }
                    {
                        store.officialDoccumentation.addressPicture1.url &&
                        <div className={"document"}>
                            <span>Domicilio 1</span>
                            <i className={"material-icons"} onClick={() => {
                                setImage(store.officialDoccumentation.addressPicture1.url);
                                setShow(true);
                            }}>visibility</i>
                        </div>
                    }
                    {
                        store.officialDoccumentation.addressPicture2.url &&
                        <div className={"document"}>
                            <span>Domicilio 2</span>
                            <i className={"material-icons"} onClick={() => {
                                setImage(store.officialDoccumentation.addressPicture2.url);
                                setShow(true);
                            }}>visibility</i>
                        </div>
                    }
                    {
                        store.officialDoccumentation.addressPicture3.url &&
                        <div className={"document"}>
                            <span>Domicilio 3</span>
                            <i className={"material-icons"} onClick={() => {
                                setImage(store.officialDoccumentation.addressPicture3.url);
                                setShow(true);
                            }}>visibility</i>
                        </div>
                    }
                    {
                        store.officialDoccumentation.menuServieMedia.url &&
                        <div className={"document"}>
                            <span>Menu</span>
                            <i className={"material-icons"} onClick={() => {
                                setImage(store.officialDoccumentation.menuServieMedia.url);
                                setShow(true);
                            }}>visibility</i>
                        </div>
                    }
                    <Modal handleClose={setShow} show={show}>
                        <img className={"document-image"} src={image} alt={""}/>
                    </Modal>
                </>
            )
        }
        return null;
    }

    return (
        <div className={"store-modal"}>
            <h5>{store.name}</h5>
            <div className={"modal-group three-columns"}>
                <label className={"modal-group-title"}>Detalles</label>
                <div className={"modal-field"}>
                    <label>ID</label>
                    <span>{store.id}</span>
                </div>
                <div className={"modal-field"}>
                    <label>ID de compañia</label>
                    <span>{store.companyId}</span>
                </div>
                <div className={"modal-field"}>
                    <label>Fecha de creacion</label>
                    <span>{store.createdAt}</span>
                </div>
            </div>
            {renderAddress()}
            {renderManager()}
            <div className={"modal-section"}>
                <div className={"modal-group documents"}>
                    <label className={"modal-group-title"}>Documentación</label>
                    {renderedPictures()}
                </div>
                {renderUsers()}
            </div>
        </div>
    );
}

export default StoreModal;