import "./SearchField.css";

function SearchField({type, value, setValue, placeholder, searchButton, handleSearch}) {
    return (
        <div className={"search-field"}>
            <input className={"browser-default"}
                   type={type !== undefined ? type : "text"}
                   placeholder={placeholder}
                   value={value}
                   onChange={(event) => setValue(event.target.value)}/>
            {searchButton && <i className={"material-icons search-button"} onClick={handleSearch}>search</i>}
        </div>
    );
}

export default SearchField;