import "./Business.css";

import {useState} from "react";

import CompaniesList from "./CompaniesList";
import StoresList from "./StoresList";

function Business() {
    const [activeTab, setActiveTab] = useState("company");

    const classText = (value) => {
        const text = "tab-item";

        if (activeTab === value) {
            return text + " active";
        }
        return text;
    }

    const content = () => {
        switch (activeTab) {
            case "company":
                return <CompaniesList/>
            case "store":
                return <StoresList/>
        }
    }

    return (
        <div className={"business section"}>
            <div className={"tab-bar"}>
                <div className={classText("company")} onClick={() => setActiveTab("company")}>
                    <span>Compañias</span>
                </div>
                <div className={classText("store")} onClick={() => setActiveTab("store")}>
                    <span>Tiendas</span>
                </div>
            </div>
            {content()}
        </div>
    );
}

export default Business;