import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {useAuth} from "../providers/authProvider";

import ControlPanel from "../features/ControlPanel/ControlPanel";
import Dashboard from "../features/Dashboard/Dashboard";
import Login from "../features/Login/Login";
import Transactions from "../features/Transactions/Transactions";
import Deposits from "../features/Deposits/Deposits";
import TransactionsGraphic from "../features/Graphics/TransactionsGraphics/TransactionsGraphic";
import DepositsGraphics from "../features/Graphics/DepositsGraphic/DepositsGraphic";
import BusinessGraphic from "../features/Graphics/BusinessGraphic/BusinessGraphic";
import TransactorsGraphic from "../features/Graphics/TransactorsGraphic/TransactorsGraphic";
import Business from "../features/Business/Business";
import Personal from "../features/Personal/Personal";
import PersonalGraphics from "../features/Graphics/PersonalGraphic/PersonalGraphic";
import RecoverPassword from "../features/Login/RecoverPassword";
import ChangePassword from "../features/Login/ChangePassword";
import Recomendations from "../features/Recomendations/Recomendations";

 const Router = () => {
    const { auth } = useAuth();

    // Define public routes
    const publicRoutes = [
        {
            path: "/login",
            element: <Login/>
        },
        {
            path: "/recover-password",
            element: <RecoverPassword/>
        }
    ];

    // Define routes accessible only to authenticated users
    const protectedRoutes = [
        {
            path: "/",
            element: <Dashboard/>,
            children: [
                {
                    path: "/",
                    element: <ControlPanel/>
                },
                {
                    path: "/transactions",
                    element: <Transactions/>
                },
                {
                    path: "/deposits",
                    element: <Deposits/>
                },
                {
                    path: "/business",
                    element: <Business/>
                },
                {
                    path: "/personal",
                    element: <Personal/>
                },
                {
                    path: "/recomendations",
                    element: <Recomendations/>
                },
                {
                    path: "/insights",
                    element: <div>Insights</div>
                },
                {
                    path: "/transactions_graphics",
                    element: <TransactionsGraphic/>
                },
                {
                    path: "/deposits_graphics",
                    element: <DepositsGraphics/>
                },
                {
                    path: "/business_graphics",
                    element: <BusinessGraphic/>
                },
                {
                    path: "/personal_graphics",
                    element: <PersonalGraphics/>
                },
                {
                    path: "/transactors",
                    element: <TransactorsGraphic/>
                }
            ]
        }
    ]

    const router = createBrowserRouter([
        ...publicRoutes,
        ...protectedRoutes
    ]);

    // Provide the router configuration using RouterProvider
    return <RouterProvider router={router}/>
}

export default Router;