import {useEffect, useState} from "react";
import M from "materialize-css";

import './Client.css'
import Modal from "../../components/Modal/Modal";

import Account from "../../api/Account";

function ClientModal({companyId, displayed, handleClose}) {
    const [client, setClient] = useState({});


    const [showApproveClientModal, setShowApproveClientModal] = useState(false);
    const [showRejectClientModal, setShowRejectClientModal] = useState(false);

    const [showActivateClientModal, setShowActivateClientModal] = useState(false);
    const [showSuspendClientModal, setShowSuspendClientModal] = useState(false);

    const [showDeleteClientModal, setShowDeleteClientModal] = useState(false);

    // Load company data
    useEffect(() => {
        if (displayed) {
            getClient();
        }
    }, [displayed]);

    // Request company data
    const getClient = async () => {
        const response = await Account.getClient(companyId);
        console.log(response)
        setClient(response);
    }

    const handleClientStatus = async (status) => {
        try {
            await Account.updateClientStatus(client.id);
            M.toast({
                html: status === 2 ? 'El cliente ha sido aprobado' : 'El cliente ha sido rechazado',
                classes: 'green'
            });
        } catch (e) {
            console.error(e);
            M.toast({
                html: 'No se pudieron aplicar los cambios',
                classes: 'red'
            });
        }

        // Close all modals
        setShowApproveClientModal(false);
        setShowRejectClientModal(false);

        // Refresh company data
        await getClient();
    }

    const handleActivateSuspendClient = async (status) => {
        try {
            switch (status) {
                case 1: // Activate
                    await Account.activate(client.id);
                    M.toast({
                        html: 'El cliente ha sido activado',
                        classes: 'green'
                    });
                    break;

                case 2: // Suspend
                    await Account.suspend(client.id);
                    M.toast({
                        html: 'El cliente ha sido suspendido',
                        classes: 'green'
                    });
                    break;
            }
        } catch (e) {
            console.error(e);
            M.toast({
                html: 'No se pudieron aplicar los cambios',
                classes: 'red'
            });
        }

        // Close all modals
        setShowActivateClientModal(false);
        setShowSuspendClientModal(false);

        // Refresh company data
        await getClient();
    }

    const handleDeleteClient = async () => {
        try {
            await Account.delete(client.id);
            M.toast({
                html: 'El cliente ha sido eliminado',
                classes: 'green'
            });

            // Close Company Modal
            handleClose(false);
        } catch (e) {
            console.error(e);
            M.toast({
                html: 'No se pudieron aplicar los cambios',
                classes: 'red'
            });
        } finally {
            // Close all modals
            setShowDeleteClientModal(false);
        }
    }

    const renderCompanyValidation = () => {
        if (client.accountStatusId === 0) {
            return (
                <div className={"company-validation"}>
                    <button className={"btn green"} onClick={() => setShowApproveClientModal(true)}>Aprobar</button>
                    <button className={"btn red"} onClick={() => setShowRejectClientModal(true)}>Rechazar</button>
                </div>
            );
        }
        return null;
    }

    const renderAccountActions = () => {
        if (client.accountStatusId === 1) {
                return <button className={"btn orange"}
                               onClick={() => setShowSuspendClientModal(true)}>Suspender</button>
        }
        else if (client.accountStatusId === 0 || 2){
            return <button className={"btn green"} onClick={() => setShowActivateClientModal(true)}>Activar</button>
        }
        return null;
    }

    const renderDeleteClient = () => {
        return <button className={"btn red"} onClick={() => setShowDeleteClientModal(true)}>Eliminar</button>
    }

    const renderActions = () => {
        if (client.delete_at === "0001-01-01T00:00:00") {
            return (
                <div className={"actions"}>
                    {renderCompanyValidation()}
                    {renderAccountActions()}
                    {renderDeleteClient()}
                </div>
            );
        }
        return (
            <div className={"actions"}>
                <label>Cuenta eliminada</label>
            </div>
        )
    }

    return (
        <div className={"company-modal"}>
            <h5>{client.firstname}</h5>
            <div className={"modal-group three-columns"}>
                <label className={"modal-group-title"}>Detalles</label>
                <div className={"modal-field"}>
                    <label>ID</label>
                    <span>{client.id}</span>
                </div>
                <div className={"modal-field"}>
                    <label>Correo electronico</label>
                    <span>{client.email}</span>
                </div>
                <div className={"modal-field"}>
                    <label>Telefono</label>
                    <span>{client.phone}</span>
                </div>
                <div className={"modal-field"}>
                    <label>Fecha de creación</label>
                    <span>{client.created_at}</span>
                </div>
            </div>
            <div className={"actions"}>
                <div className={"modal-group"}>
                    <label className={"modal-group-title"}>Acciones</label>
                    {renderActions()}
                </div>
            </div>
            <Modal handleClose={setShowApproveClientModal} show={showApproveClientModal}>
                <h5>Aprobar cliente</h5>
                <button className={"btn green"} onClick={() => handleClientStatus(2)}>Confirmar</button>
            </Modal>
            <Modal handleClose={setShowRejectClientModal} show={showRejectClientModal}>
                <h5>Rechazar cliente</h5>
                <button className={"btn red"} onClick={() => handleClientStatus(3)}>Confirmar</button>
            </Modal>
            <Modal handleClose={setShowActivateClientModal} show={showActivateClientModal}>
                <h5>Activar cliente</h5>
                <button className={"btn green"} onClick={() => handleActivateSuspendClient(1)}>Confirmar</button>
            </Modal>
            <Modal handleClose={setShowSuspendClientModal} show={showSuspendClientModal}>
                <h5>Suspender cliente</h5>
                <button className={"btn orange"} onClick={() => handleActivateSuspendClient(2)}>Confirmar</button>
            </Modal>
            <Modal handleClose={setShowDeleteClientModal} show={showDeleteClientModal}>
                <h5>Eliminar cliente</h5>
                <button className={"btn red"} onClick={() => handleDeleteClient()}>Confirmar</button>
            </Modal>
        </div>
    );
}

export default ClientModal;