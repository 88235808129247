import { useEffect, useState } from "react";
import SearchField from "../../components/SearchField/SearchField";
import Recommendations from "../../api/Recommendations";
import Filter from "../../components/Filter/Filter";
import Table from "../../components/Table/Table";
import Pagination from "../../components/Pagination/Pagination";
import Modal from "../../components/Modal/Modal";

function RecomendationsList() {
    const [option, setOption] = useState({});
    const [name, setName] = useState("");
    const [id, setId] = useState("");
    const [params, setParams] = useState({ currentPage: 0, offset: 10 });
    const [user, setUser] = useState({});
    const [clients, setClients] = useState([]);
    const [pages, setPages] = useState(0);
    const [show, setShow] = useState(false);

    const handleParams = () => {
        // Implement your logic here based on selected option
    };

    const reset = () => {
        setParams({
            currentPage: 0,
            offset: 10
        });
        setOption(options[0]);
    };

    useEffect(() => {
        fetchRecommendations();
    }, []);
    

    const fetchRecommendations = async () => {
        try {
            const recommendationData = await Recommendations.getRecommendations();
            setPages(1);
            setClients(recommendationData);
            console.log(recommendationData)
        } catch (error) {
            console.error("Error fetching recommendations:", error);
        }
    };

    const options = [
        {
            id: 1,
            text: "Nombre",
            component: <SearchField key={"1"} value={name} setValue={setName} placeholder={"Nombre de cliente"}
                searchButton={true} handleSearch={handleParams} />
        },
        {
            id: 3,
            text: "ID",
            component: <SearchField key={"3"} value={id} setValue={setId} placeholder={"ID de compañia"}
                searchButton={true} handleSearch={handleParams} />
        }
    ];

    const tableConfig = [
        {
            header: "ID",
            content: item => item.id
        },
        {
            header: "Nombre",
            content: item => item.businessName
        },
        {
            header: "Fecha de creacion",
            content: item => item.createdAt
        },
        {
            header: "Teléfono",
            content: item => item.phone
        },
        {
            header: "Detalles",
            content: (item) => {
                return (
                    <i
                        className={"material-icons"}
                        onClick={() => {
                            setUser(item);
                            setShow(true);
                        }}
                    >
                        article
                    </i>
                )
            }
        },
    ];

    return (
        <div className={"companies-list"}>
            <Filter text={"Buscar por"} options={options} option={option} setOption={setOption} resetFilter={reset}>
                {options.map(option => option.component)}
            </Filter>
            <Table config={tableConfig} data={clients} />
            <Pagination page={params.currentPage} setPage={(value) => setParams({ ...params, currentPage: value })} total={pages} />
            <Modal handleClose={setShow} show={show}>
                {user && user.recommendedBy && (
                    <div className="company-modal">
                        <h5>Detalles</h5>
                        <div className="modal-group">
                        <label className={"modal-group-title"}>Recomendado Por:</label>
                        <p>Nombre: {user.recommendedBy.firstname} {user.recommendedBy.lastname}</p>
                        <p>Teléfono: {user.recommendedBy.phone} </p>
                        <p>Correo: {user.recommendedBy.email}</p>
                        </div>
                    </div>
                )}
            </Modal>
        </div>
    );
}

export default RecomendationsList;
