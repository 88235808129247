import React, {useEffect, useState} from "react";
import Chart from "react-apexcharts";

import Dropdown from "../../../components/Dropdown/Dropdown";
import Filter from "../../../components/Filter/Filter";
import BusinessChart from "./BusinessChart";

function BusinessGraphic() {
    return (
        <div className="grid-charts">
        <BusinessChart/>
        <BusinessChart/>
        <BusinessChart/>
        <BusinessChart/>
     </div>
    );
}

export default BusinessGraphic;